<template>
    <div class="modal-profile-item-selector bg-main">
        <div class="back-button-wrapper" @click="onClickClose()">
            <i class="back-button material-icons">{{ 'close' }} </i>
        </div>
        <div class="top">
            <div class="title text-center f-18" v-html="title" />
        </div>
        <div class="input-wrapper items-center">
            <i class="material-icons i-search">search</i>
            <input
                ref="searchValue"
                v-model="value"
                @keydown="onKeydown"
                :placeholder="$translate(`SEARCH_NAME_BLOCK`)"
            />
            <i v-if="value" @click="onClickCancel" class="material-icons">cancel</i>
        </div>
        <div class="result-wrapper" v-if="searchResults.length && value">
            <div class="result" v-for="(res, idx) in searchResults" :key="idx">
                <span class="c-black" v-html="`${res.college_name || ''} ${res.name}`" />
                <span @click="onClickBlock(res)" class="c-primary f-medium" v-html="$translate('BLOCK')" />
            </div>
        </div>
    </div>
</template>

<script>
import debounce from '@/modules/debounce'
import userService from '@/services/user'

export default {
    name: 'ModalBlockInput',
    props: ['options'],
    data: () => ({
        value: null,
        searchResults: [],
    }),
    computed: {
        blockType() {
            return this.options.blockType
        },
        title() {
            if (this.blockType === 'username') return '이름'
            else return '직장명 (고유명사)'
        },
    },
    methods: {
        onKeydown() {
            this.search()
        },
        onClickClose() {
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
            this.$emit('close')
        },
        onClickCancel() {
            this.searchResults = []
            this.value = ''
        },
        search: debounce(async function () {
            this.searchResults = [
                {
                    name: this.$refs.searchValue.value,
                },
            ]
        }, 200),
        async onClickBlock(resp) {
            try {
                const name = resp.name
                const { msg, res } = await userService.blockProfile(this.$store.getters.me.id, {
                    type: 'username',
                    name,
                })

                const { data } = await userService.getBlockProfile(this.$store.getters.me.id, 'username')
                if (res === 'fail') this.$toast.error(msg)
                else this.$toast.success(msg)

                this.$store.commit('setBlockedName', data)
            } catch (e) {
                this.$toast.error(e.data.msg)
            }
        },
    },
    created() {
        this.$nativeBridge.postMessage({
            action: 'setBackgroundColor',
            value: '#FFF9EF',
        })
    },
}
</script>
